<!--
 * @Author: 月魂
 * @Date: 2021-04-29 17:59:40
 * @LastEditTime: 2021-05-20 13:51:37
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Mobile.vue
-->
<template>
  <div class="box">
     <van-nav-bar
      title="手机号认证"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <van-field v-model="mobile" type="tel" label="手机号" name="mobile" placeholder="请输入手机号" />
    <van-field
      v-model="code"
      center
      clearable
      label="短信验证码"
      placeholder="请输入短信验证码"
    >
      <template #button>
        <van-button round size="small" type="danger" :disabled="visible" @click="sendCode">
          <span v-if="!visible">发送验证码</span>
          <van-count-down v-else :time="time" format="ss 秒" style="color: #fff" @finish="timeFinished" />
        </van-button>
      </template>
    </van-field>
    <div style="margin-top: 60px;padding: 10px;">
      <van-button type="danger" size="large" round @click="submitCode">提交</van-button>
    </div>
    
  </div>
</template>

<script>
import Vue from 'vue';
import { mobileReg } from '@/utils'
import { getCode, validateCode } from '@/api/user'
import { NavBar, Icon, Field, Button, CountDown, Toast } from 'vant';
Vue.use(NavBar).use(Icon).use(Field).use(Button).use(CountDown)

export default {
  name: 'Mobile',
  data() {
    return {
      mobile: '',
      code: '',
      token: '',
      time: 300 * 1000,
      visible: false,
    }
  },
  mounted() {
    const mobile = localStorage.getItem('mobile')
    const token = localStorage.getItem('code')
    const time = localStorage.getItem('time')
    if (mobile && token && time) {
      const now = new Date().getTime()
      if ((now - time) / 1000 < 300) {
        this.mobile = mobile
        this.token = token
        this.visible = true
        this.time = 300 * 1000 - Math.floor(now - time)
      } else {
        this.mobile = mobile
        localStorage.removeItem('code')
        localStorage.removeItem('time')
      }
    } else {
      this.mobile = mobile || ''
      this.token = ''
      localStorage.removeItem('code')
      localStorage.removeItem('time')
    }
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    sendCode() {
      if (mobileReg(this.mobile)) {
        localStorage.setItem('mobile', this.mobile)
        getCode(this.mobile).then(res => {
          if (res.code === 0) {
            this.visible = true
            this.time = 300 * 1000
            this.token = res.msg
            const time = new Date().getTime()
            localStorage.setItem('code', res.msg)
            localStorage.setItem('time', time)
          }
        })
      } else {
        Toast.fail('请输入正确格式手机号码')
      }
    },
    timeFinished() {
      this.visible = false
      this.time = 300 * 1000
    },
    submitCode() {
      if (this.mobile && this.token !== '' && this.code !== '') {
        const params = {
          mobile: this.mobile,
          token: this.token,
          code: this.code
        }
        validateCode(params).then(res => {
          if (res.code === 0) {
            Toast.success('验证成功')
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            const redirectUrl = localStorage.getItem('redirectUrl')
            userInfo.mobile = this.mobile
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            if (redirectUrl) {
              this.$router.replace(redirectUrl)
            } else {
              this.$router.go(-1)
            }
          }
          localStorage.removeItem('mobile')
          localStorage.removeItem('code')
          localStorage.removeItem('time')
          this.visible = false
          this.time = 300 * 1000
        })
      }
    }
  }
}
</script>
